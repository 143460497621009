const ProjectOptions = {
  stages: {
    'auditing': '平台审核',
    'confirming': '指派任务',
    'contacting': '双方沟通',
    'paying': '费用托管',
    'working': '工作状态',
    'rework': '工作状态',
    'delivering': '项目交付',
    'finish': '双方评价',
  },
  status: {
    auditing: {
      running: '审核中',
      contractorCancel: '',
      ownerCancel: '已撤销任务',
      platformCancel: '审核不通过',
    },
    confirming:{
      running: '任务待确认',
      contractorCancel: '拒接任务',
      ownerCancel: '已撤销任务',
      platformCancel: '平台终止流程',
    },
    contacting:{
      running: '等待服务方确认需求',
      contractorCancel: '拒接任务',
      ownerCancel: '已撤销任务',
      platformCancel: '平台终止流程',
    },
    paying:{
      running: '待支付',
      contractorCancel: '拒接任务',
      ownerCancel: '已撤销任务',
      platformCancel: '平台终止流程',
    },
    working:{
      running: '工作中',
      contractorCancel: '',
      ownerCancel: '',
      platformCancel: '平台终止流程',
    },
    rework:{
      running: '工作中',
      contractorCancel: '',
      ownerCancel: '',
      platformCancel: '平台终止流程',
    },
    delivering:{
      running: '待验收',
      contractorCancel: '',
      ownerCancel: '',
      platformCancel: '平台终止流程',
    },
    finish:{
      running: '评价中',
      contractorCancel: '',
      ownerCancel: '',
      platformCancel: '平台终止流程',
    },
  }
}

export default ProjectOptions
